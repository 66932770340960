.rb-isotope {
    &::after {
        content: "";
        display: table;
        clear: both;
    }

    // with gap
    &.rb-isotope-gap {
        margin: -15px;

        .rb-isotope-item {
            padding: 15px;
        }
    }
    &.rb-isotope-gap-big {
        margin: -30px;

        .rb-isotope-item {
            padding: 30px;
        }
    }
    &.rb-isotope-gap-small {
        margin: -10px;

        .rb-isotope-item {
            padding: 10px;
        }
    }

    // container fluid fix
    .container-fluid & {
        margin-right: -15px;
        margin-left: -15px;

        &.rb-isotope-gap {
            margin-right: -30px;
            margin-left: -30px;
        }
        &.rb-isotope-gap-big {
            margin: -60px;

            .rb-isotope-item {
                padding: 60px;
            }
        }
        &.rb-isotope-gap-small {
            margin-right: -25px;
            margin-left: -25px;
        }
    }

    // items
    .rb-isotope-item {
        display: block;
        float: left;
        width: 25%;

        &.rb-isotope-item-x2 {
            width: 50%;
        }
        &.rb-isotope-item-x3 {
            width: 75%;
        }
        &.rb-isotope-item-x4 {
            width: 100%;
        }
    }

    // 3 columns
    &.rb-isotope-3-cols {
        .rb-isotope-item {
            width: 33.332%;

            &.rb-isotope-item-x2 {
                width: 66.665%;
            }
            &.rb-isotope-item-x3 {
                width: 100%;
            }
        }
    }

    // 2 columns
    &.rb-isotope-2-cols {
        .rb-isotope-item {
            width: 50%;

            &.rb-isotope-item-x2,
            &.rb-isotope-item-x3 {
                width: 100%;
            }
        }
    }

    // 1 columns
    &.rb-isotope-1-cols {
        .rb-isotope-item {
            width: 100%;

            &.rb-isotope-item-x2,
            &.rb-isotope-item-x3 {
                width: 100%;
            }
        }
    }

    // wide 100%
    &.rb-isotope-wide {
        .rb-isotope-item {
            width: 100%;
        }
    }

    @media #{$media_smaller_md} {
        .rb-isotope-item,
        &.rb-isotope-3-cols .rb-isotope-item {
            width: 33.3334%;

            &.rb-isotope-item-x2,
            &.rb-isotope-item-x3 {
                width: 100%;
            }
        }
    }

    @media #{$media_smaller_sm} {
        .rb-isotope-item,
        &.rb-isotope-3-cols .rb-isotope-item,
        &.rb-isotope-2-cols .rb-isotope-item {
            width: 50%;

            &.rb-isotope-item-x2,
            &.rb-isotope-item-x3 {
                width: 100%;
            }
        }
    }

    @media #{$media_smaller_xs} {
        .rb-isotope-item,
        .rb-isotope-item.rb-isotope-item-x2,
        .rb-isotope-item.rb-isotope-item-x3,
        &.rb-isotope-3-cols .rb-isotope-item,
        &.rb-isotope-2-cols .rb-isotope-item {
            width: 100%;
        }
    }
}

// filter
.rb-isotope-filter {
    display: none;
    padding: 0;
    margin-right: -17px;
    margin-bottom: 30px;
    margin-left: -17px;
    text-align: center;
    list-style: none;

    &.rb-isotope-filter-active {
        display: block;
    }

    > li {
        display: inline-block;
        padding: 5px 17px;
        font-size: .82rem;
        font-weight: 500;
        text-transform: uppercase;
        cursor: pointer;
        transition: .5s color;

        &.active,
        &:hover {
            color: #000;
        }
    }
}
.rb-pagination + div > .rb-isotope-filter,
.rb-pagination + .rb-isotope-filter {
    margin-top: -23px;
}
.rb-pagination.rb-isotope-filter-active a {
    opacity: .3;
}
