.rb-tabs {
    .nav-tabs {
        display: block;
        margin-top: -10px;
        margin-bottom: 27px;
        border-bottom: none;

        .nav-item {
            display: inline-block;
            float: none;
            color: $color_titles;
        }

        .nav-link,
        .nav-item.open .nav-link,
        .nav-link.active {
            &,
            &:focus,
            &:hover {
                color: inherit;
                background-color: transparent;
            }
        }

        .nav-link {
            position: relative;
            padding: 10px 16px;
            font-size: .955rem;
            font-weight: 500;
            text-transform: uppercase;
            border: none;
            border-bottom: 2px solid transparent;
            transition: .5s opacity;

            &:hover {
                opacity: .6;
            }

            &::after {
                content: "";
                position: absolute;
                display: block;
                right: 10px;
                bottom: 6px;
                left: 10px;
                border-bottom: 1px solid transparent;
                transition: .5s border-bottom;
            }
        }
        .nav-link.active {
            &,
            &:focus,
            &:hover {
                &::after {
                    border-bottom: 1px solid;
                }
            }
        }
    }
}
