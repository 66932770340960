/* Isotope Posts list */
.rb-blog-isotope .rb-blog-post {
    padding: 25px 30px;
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .03);

    .rb-post-thumb {
        position: relative;
        margin-top: -25px;
        margin-right: -30px;
        margin-bottom: 21px;
        margin-left: -30px;

        > a {
            transition: .5s opacity;
            &:hover {
                opacity: .6;
            }
        }
    }

    .rb-post-category a {
        display: inline-block;
        bottom: 9px;
        left: 10px;
        min-width: 90px;
        padding: 5.5px 15px;
        margin-top: 25px;
        margin-left: 30px;
        font-size: .8rem;
        font-weight: 500;
        color: #fff;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        background-color: $color_dark_1;
        transition: .5s background-color;

        &:hover {
            background-color: $color_dark_3;
        }
    }
    a + .rb-post-category a {
        position: absolute;
        display: block;
        margin-top: 0;
        margin-left: 0;
    }

    .rb-post-date {
        font-size: .84rem;
        font-weight: 500;
        color: $color_titles;
        text-transform: uppercase;
    }

    .rb-post-title {
        margin-bottom: 15px;
    }

    .rb-post-text {
        margin-top: 12px;
        margin-bottom: 0;
    }
}

/* Isotope Posts list Style 2 */
.rb-blog-isotope-2 .rb-blog-post {
    position: relative;
    text-align: center;
    background-color: #0c0c0c;
    z-index: 1;

    .rb-post-thumb {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: 50% 50%;
        background-size: cover;
        opacity: .5;
        transition: .5s opacity;
        z-index: -1;
    }
    &:hover .rb-post-thumb,
    &.hover .rb-post-thumb {
        opacity: .2;
    }

    .rb-post-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 400px;
        padding: 40px;
    }

    .rb-post-date {
        margin-bottom: 17px;
        font-size: .84rem;
        font-weight: 500;
        color: #fff;
        text-transform: uppercase;
    }

    .rb-post-title {
        margin-bottom: 19px;
        color: #fff;
    }

    .rb-post-more {
        position: relative;
        display: inline-block;
        font-size: .97rem;
        font-weight: 500;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;

        &::after {
            content: "";
            position: absolute;
            display: block;
            bottom: -1px;
            left: 50%;
            width: 20px;
            margin-left: -10px;
            border-bottom: 1px solid;
            transition: .5s transform, .5s width;
        }

        &:hover::after,
        &.hover::after {
            width: 30px;
            transform: translateX(-5px);
        }
    }
}


/* Single Post */
.rb-blog-post-single {
    h1 {
        margin-top: 8px;
    }

    .rb-post-date,
    .rb-post-category,
    .rb-post-comments-count {
        font-size: .84rem;
        font-weight: 500;
        color: $color_titles;
        text-transform: uppercase;

        a {
            color: inherit;
            text-decoration: none;
            transition: .5s opacity;
        }
        a:hover {
            opacity: .6;
        }
    }

    .rb-post-meta {
        margin-right: -22px;
        margin-left: -22px;

        > div {
            display: inline-block;
            margin: 0 22px;
        }
    }

    .rb-post-text {
        color: $color_text_blog;
    }

    .rb-post-share {
        margin-bottom: -6px;
        color: $color_titles;

        strong {
            margin-right: 5px;
            font-weight: 500;
        }

        a {
            color: inherit;
            text-decoration: none;
            transition: .5s opacity;
        }
        a:hover {
            opacity: .6;
        }
    }
}

.rb-post-title > a {
    color: inherit;
    text-decoration: none;
    transition: .3s opacity;

    &:hover,
    &.hover {
        opacity: .6;
    }
}

.rb-post-text {
    margin: 25px 0;

    &::after,
    &::before {
        content: "";
        display: table;
        clear: both;
    }

    .pull-right,
    .pull-left {
        margin: 15px;
    }
    .pull-right {
        margin-right: 0;
    }
    .pull-left {
        margin-left: 0;
    }

    > p:last-child {
        margin-bottom: 0;
    }
}

// comments
$comments_avatar_width: 100px !default;
$comments_avatar_width_sm: 50px !default;
.rb-comments {
    background-color: #fff;

    h3:first-of-type {
        margin-top: -6px;
    }

    .rb-comment {
        margin-top: -5px;

        &::after,
        &::before {
            content: "";
            display: table;
            clear: both;
        }

        .rb-comment {
            margin-left: $comments_avatar_width;
        }

        .rb-comment,
        + .rb-comment {
            padding-top: 19px;
            clear: both;
        }

        .rb-comment-avatar {
            position: relative;
            float: left;
            margin-top: 7px;
            margin-right: 30px;
            z-index: 1;

            img {
                width: $comments_avatar_width;
                height: auto;
                border-radius: $comments_avatar_width / 2;
                transition: .5s opacity;
            }

            a:hover img,
            a.hover img{
                opacity: .6;
            }
        }

        // comment meta
        .rb-comment-meta {
            position: relative;
            padding-bottom: 38px;
            margin-right: -22px;
            margin-bottom: 3px;
            margin-left: -22px;

            .rb-comment-name,
            .rb-comment-date,
            .rb-comment-reply {
                margin-right: 22px;
                margin-left: 22px;
                font-weight: 500;
                color: $color_titles;

                a {
                    color: inherit;
                    text-decoration: none;
                    transition: .5s opacity;
                }
                a:hover {
                    opacity: .6;
                }
            }
            .rb-comment-name {
                float: left;
                margin-left: 0;
            }
            .rb-comment-date,
            .rb-comment-reply {
                float: right;
                font-size: .84rem;
                text-transform: uppercase;
            }
        }

        .rb-comment-text {
            margin-left: $comments_avatar_width + 30px;
            color: $color_text_blog;
        }

        // mobile
        @media #{$media_smaller_md} {
            .rb-comment {
                margin-left: $comments_avatar_width_sm;
            }
            .rb-comment-meta {
                padding-bottom: 20px;
                margin-bottom: 15px;
            }

            .rb-comment-avatar {
                margin-right: 20px;

                img {
                    width: $comments_avatar_width_sm;
                    border-radius: $comments_avatar_width_sm / 2;
                }
            }
            .rb-comment-text {
                margin-left: $comments_avatar_width_sm + 20px;
            }
        }
        @media #{$media_smaller_xs} {
            .rb-comment {
                margin-left: 20px;
            }
            .rb-comment-avatar {
                margin-top: 0;
            }
            .rb-comment-meta {
                padding-top: 13px;
                padding-bottom: 0;
            }
            .rb-comment-text {
                padding-top: 15px;
                margin-left: 0;
                clear: both;
            }
        }
    }
}

// reply
.rb-reply {
    h3:first-of-type {
        margin-top: -6px;
    }
}
