.rb-fullpage-portfolio {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    .rb-fullpage-item {
        display: none;
    }

    .rb-fullpage-bg-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: 50% 50%;
        background-size: cover;
        z-index: 0;
    }


    .rb-fullpage-view-button {
        position: absolute;
        bottom: 50px;
        left: 0;
        width: 100%;
    }
    .rb-fullpage-content {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .rb-fullpage-item-category {
        margin-bottom: 10px;
        font-size: .848rem;
        font-weight: 500;
        line-height: 1;
        text-transform: uppercase;

        a {
            color: inherit;
            text-decoration: none;
            transition: .5s color;

            &:hover {
                color: $color_titles;
            }
        }
    }
    .rb-fullpage-title a {
        color: inherit;
        text-decoration: none;
        transition: .5s opacity;

        &:hover {
            opacity: .6;
        }
    }
}

// right nav
.rb-fullpage-nav {
    position: fixed;
    top: 50%;
    right: 40px;
    padding: 0;
    margin: 0;
    list-style: none;
    visibility: hidden;
    opacity: 0;
    transition: .3s visibility, .3s opacity, .3s transform;
    transform: translateY(-50%) translateX(100%);
    z-index: 2;

    &.active {
        visibility: visible;
        opacity: 1;
        transform: translateY(-50%) translateX(0);
    }

    > li {
        position: relative;
        width: 20px;
        padding-top: 6px;
        padding-bottom: 6px;
        color: $color_titles;
        text-transform: uppercase;
        cursor: pointer;

        &::after {
            content: "";
            position: absolute;
            display: block;
            top: 50%;
            left: 110%;
            width: 100px;
            height: 1px;
            background-color: $color_titles;
            transition: .5s transform;
            transform: scale(0);
        }
        &.active::after {
            transform: scale(1);
        }
    }
}

// slide number
.rb-fullpage-number {
    position: fixed;
    display: block;
    right: 50px;
    bottom: 50px;
    font-family: $font_headings;
    font-size: 3.5rem;
    font-weight: 600;
    line-height: 1;
    color: #c8c8c8;
    z-index: 1;
}
