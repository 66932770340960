.page-home {

	#scroll-btn {
		display: none;
		opacity: 0;
	}

	#about {

		padding: 30px 0;

		@media only screen and (min-width: 992px) {
			padding: 60px 0;			
		}

		.about-me-photo-col {

			padding: 0;

			.about-me-photo {
				width: 100%;
				height: auto;
			}
		}

		.about-me-text {
			@media only screen and (min-width: 768px) {
				padding-left: 60px;
			}
		}
	}

	#skills {

		padding-bottom: 60px;

		@media only screen and (min-width: 992px) {
			padding-bottom: 90px;
		}

		.skill-box {

			padding-top: 40px;

			.rb-ibox-1 {
			
			    height: 160px;
			
				.rb-ibox-cont {

					width: 100%;
					margin: 0;
					text-align: center;

					.rb-ibox-title {
					    font-size: 1.5em;
					}

				}

				.rb-ibox-icon {

					width: 100%;

					img {
						width: 120px;
					}


					&.js-icon {
						img {
					    	width: 98px;						
						}
					}

					&.sass-icon {
						img {
						    width: 120px;
						    margin-top: 10px;						
						}
					}

					&.react-icon {
						img {
					    	// width: 98px;						
						}
					}

					&.html5-icon {
						img {
	    					width: 96px;					
						}
						
					}

					&.css3-icon {
						img {
						    width: 115px;
						    margin-top: 5px;						
						}
						
					}

					&.git-icon {
						img {
							width: 112px;					
						}
						
					}

				}
			}
		}

	}

	#contact {

		#form-submit-btn {

			@media only screen and (min-width: 768px) {
				float: left;
			}

		}

	}

	#projects {
		background-color: $color_bg;
	}

}