html {
    // fix for isotope small gap on some pages on page load on windows
    overflow-y: scroll;
}
body {
    color: $color_text;
    background-color: $color_bg;
    font-size: 16px;
}
.rb-main {
    position: relative;
    overflow: hidden;
    background-color: $color_bg;
    z-index: 1;
}

// bg gradient
body.rb-bg-gradient .rb-main {
    background: linear-gradient(to bottom, #fff, $color_bg);
}

// boxed
body.rb-page-boxed {
    max-width: 1200px;
    margin: 0 auto;
}

// Links
a {
    color: $color_main_1;
}
a:focus,
a:hover {
    color: darken($color_main_1, 10%);
}

// selection color
::selection {
    color: #fff;
    background-color: $color_dark_4;
}

// block for any content (most case of use - parallax and image backgrounds)
.rb-block {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1;

    &::after,
    &::before {
        content: "";
        display: table;
        clear: both;
    }
}

// bg image
.bg-image,
.bg-video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 50% 50%;
    background-size: cover;
    z-index: -1;

    > div {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: 50% 50%;
        background-size: cover;
    }
}
.bg-video {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.bg-image-row,
.bg-video-row,
.bg-map-row {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    > div {
        height: 100%;
        overflow: hidden;
    }

    @media #{$media_smaller_sm} {
        position: relative;
        > div {
            padding-top: 56.25%;
            > div {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }
    }
}
.bg-map-row {
    z-index: auto;
    .rb-gmaps {
        height: 100%;
        z-index: 1;
    }
    @media #{$media_smaller_sm} {
        position: relative;
        > div {
            padding-top: 0;
            > div {
                position: relative;
            }
        }
        .rb-gmaps {
            height: 300px;
        }
        .rb-gmaps-sm {
            height: 250px;
        }
        .rb-gmaps-md {
            height: 450px;
        }
        .rb-gmaps-lg {
            height: 550px;
        }
        .rb-gmaps-full {
            height: 700px;
            height: 100vh;
            min-height: 700px;
        }
    }
}

// background color box
.bg-color {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color_dark_1;
    z-index: -1;
}

// gaps
.rb-gap,
.rb-gap-1,
.rb-gap-2,
.rb-gap-3,
.rb-gap-4,
.rb-gap-5,
.rb-gap-6 {
    display: block;
    height: 20px;

    &::after,
    &::before {
        content: "";
        display: table;
        clear: both;
    }
}
.rb-gap-1 {
    height: 30px;
}
.rb-gap-2 {
    height: 40px;
}
.rb-gap-3 {
    height: 60px;
}
.rb-gap-4 {
    height: 80px;
}
.rb-gap-5 {
    height: 100px;
}
.rb-gap-6 {
    height: 120px;
}

// body scrollbar checker
.rb-body-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

// gmaps
.rb-gmaps {
    width: 100%;
    height: 300px;
}
.rb-gmaps-sm {
    height: 250px;
}
.rb-gmaps-md {
    height: 450px;
}
.rb-gmaps-lg {
    height: 570px;
}
.rb-gmaps-full {
    height: 700px;
    height: 100vh;
    min-height: 700px;
}

// badges
.rb-badge {
    display: inline-block;
    min-width: 15px;
    height: 15px;
    padding: 0 4px;
    font-size: 10px;
    line-height: 15px;
    color: $color_dark_1;
    text-align: center;
    background-color: #fff;
    border-radius: 7.5px;

    .rb-navbar li > & {
        position: absolute;
        right: 5px;
        bottom: 0;
    }
}

.rb-scroll-top {
    cursor: pointer;
}

// code
pre {
    position: relative;
    max-height: 500px;
    padding: 25px;
    line-height: 1.3;
    color: #fff;
    background-color: $color_dark_3;

    .tag {
        display: inline-block;
        padding: 0;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-align: inherit;
        white-space: inherit;
        vertical-align: inherit;
        border-radius: 0;
    }
}
pre[class*="language-"] {
    margin: 0;
}


/*  DEPRECATED, added for back compatibility */
// vertical center block (use Bootstrap 4 flexboxes)
.rb-vertical-center {
    display: table;
    width: 100%;
    height: 100%;

    > div {
        display: table-cell;
        vertical-align: middle;
    }
}
// no-gap, use bootstrap .no-gutters
.row.no-gap {
    margin-right: 0;
    margin-left: 0;
}
.row.no-gap > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}
// text align
.text-xs-center {
    text-align: center;
}
.text-xs-left {
    text-align: left;
}
.text-xs-right {
    text-align: right;
}
