.breakpoint-check {

	height: 0;
	width: 0;
	overflow: hidden;

	opacity: 0;

	@media only screen and (min-width: 768px) {
		opacity: 0.6;
	}

	@media only screen and (min-width: 992px) {
		opacity: 0.8;
	}

	@media only screen and (min-width: 1200px) {
		opacity: 1;
	}


}

.clear {
	clear: both;
}