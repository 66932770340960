
.rb-btn {
    display: inline-block;
    padding: 18px 25px;
    font-size: .96rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: $color_dark_1;
    border: 0;
    transition: .5s all;

    &,
    &:hover,
    &.hover,
    &:active,
    &.active,
    &:focus {
        color: #fff;
        text-decoration: none;
    }
    &:hover,
    &.hover,
    &:active,
    &.active,
    &:focus {
        background-color: $color_dark_4;
    }
}

// sizes
.rb-btn-xs {
    padding: 8px 16px;
    font-size: .9rem;

    // outline fix
    &.rb-btn-outline {
        padding-top: 7px;
        padding-bottom: 7px;
    }
}
.rb-btn-sm {
    padding: 10px 20px;

    // outline fix
    &.rb-btn-outline {
        padding-top: 9px;
        padding-bottom: 9px;
    }
}
.rb-btn-lg {
    padding: 14px 30px;

    // outline fix
    &.rb-btn-outline {
        padding-top: 13px;
        padding-bottom: 13px;
    }
}

// long
.rb-btn-long {
    padding-right: 43px;
    padding-left: 43px;

    &.rb-btn-xs {
        padding-right: 36px;
        padding-left: 36px;
    }
    &.rb-btn-sm {
        padding-right: 40px;
        padding-left: 40px;
    }
    &.rb-btn-lg {
        padding-right: 50px;
        padding-left: 50px;
    }
}

// block
.rb-btn-block {
    display: block;
    width: 100%;
}


/* Button Style 2 */
.rb-btn-2 {
    position: relative;
    display: inline-block;
    padding: 0 3px;
    font-size: .96rem;
    font-weight: 500;
    color: $color_titles;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;

    &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 3px;
        width: 40px;
        border-bottom: 1px solid;
        opacity: .2;
        transition: .5s transform, .5s opacity;
        transform-origin: 0 0;
    }

    &:hover::after,
    &.hover::after {
        opacity: .5;
        transform: scaleX(1.2);
    }

    &:hover,
    &:active,
    &:focus {
        color: $color_titles;
        text-decoration: none;
    }
}
