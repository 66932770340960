.rb-navbar-side {
    position: fixed;
    top: 0;
    bottom: 0;
    padding: 0;
    overflow: hidden;
    background-color: $color_dark_2;
    z-index: 1002;

    @mixin rightSideSize($width) {
        width: $width;
        margin-right: -$width;
    }
    @mixin leftSideSize($width) {
        width: $width;
        margin-left: -$width;
    }

    // left side
    &.rb-navbar-left-side {
        left: 0;
        @include leftSideSize(250px);

        @media (max-width: 400px) {
            @include leftSideSize(200px);
        }
    }

    // right side
    &.rb-navbar-right-side {
        right: 0;
        @include rightSideSize(250px);

        @media (max-width: 400px) {
            @include rightSideSize(200px);
        }
    }

    // large size
    &.rb-navbar-lg {
        // left side
        &.rb-navbar-left-side {
            @include leftSideSize(450px);
            @media (max-width: 550px) {
                @include leftSideSize(300px);
            }
            @media (max-width: 450px) {
                @include leftSideSize(250px);
            }
        }

        // right side
        &.rb-navbar-right-side {
            @include rightSideSize(450px);
            @media (max-width: 550px) {
                @include rightSideSize(300px);
            }
            @media (max-width: 450px) {
                @include rightSideSize(250px);
            }
        }

        .rb-nav .dropdown > li,
        .rb-nav > li {
            &:last-of-type > a,
            > a {
                padding-right: 80px;
                padding-left: 80px;

                @media (max-width: 550px) {
                    padding-right: 40px;
                    padding-left: 40px;
                }
            }
        }
    }

    // close icon
    .rb-navbar-side-close {
        position: absolute;
        top: 0;
        right: 0;
        padding-top: 44px;
        padding-right: 53px;
        padding-bottom: 30px;
        padding-left: 30px;
        color: #d8d8d8;
        cursor: pointer;
        z-index: 1;

        &:hover {
            color: #fff;
        }
    }

    .rb-nav-table {
        height: auto;
        min-height: 100%;
        max-height: 100%;
        padding-top: 105px;
    }

    // navigation
    .nano {
        overflow-y: auto;
    }
    .rb-nav {
        position: relative;
        width: 100%;
        padding: 0;
        padding-top: 105px;

        .dropdown > li,
        > li {
            position: static;
            display: block;

            &:last-of-type > a,
            > a {
                padding: 10px 40px;
                font-family: $font_headings;
                font-size: 1.7rem;
                font-weight: 600;
                line-height: 1.1;
                color: #d8d8d8;
                text-transform: none;
                white-space: normal;

                @media (max-width: 400px) {
                    font-size: 1.3rem;
                }
            }

            &.active > a,
            > a:hover {
                color: #fff;
            }

            > a::before,
            > a::after {
                content: none;
            }
        }

        .rb-drop-item .rb-drop-item > a::after,
        .rb-drop-item > a::after,
        > .rb-drop-item > a::after {
            content: "";
            position: absolute;
            display: block;
            top: 50%;
            right: 40px;
            left: auto;
            width: 0;
            height: 0;
            margin-top: -1px;
            color: inherit;
            border: none;
            border-top: 2px solid transparent;
            border-bottom: 2px solid transparent;
            border-left: 2px solid;
            opacity: .6 !important; // stylelint-disable-line
            transition: .3s opacity;
            transform: none;
            will-change: opacity;
        }
        .rb-drop-item > a:hover::after,
        .rb-drop-item > a.hover::after,
        > .rb-drop-item > a:hover::after,
        > .rb-drop-item > a.hover::after {
            width: 0;
            height: 0;
            opacity: 1 !important; // stylelint-disable-line
        }

        // drop item icon
        .rb-drop-item > a,
        > .rb-drop-item > a,
        .bropdown-back > a {
            position: relative;
        }
    }

    // dropdown
    .rb-nav .dropdown {
        display: block;
        padding: 0;
        visibility: inherit;
        background-color: transparent;
        opacity: 1;
        transform: none;

        &::before {
            content: none;
        }
    }
    .rb-nav .rb-drop-item > .dropdown,
    .rb-nav .rb-drop-item .rb-drop-item > .dropdown {
        top: 0;
        left: 0;
        width: 100%;
        margin-top: 0;
        margin-left: 0;

        > li > a {
            display: none;
        }
    }
    // back icon
    .dropdown > .bropdown-back > a::after {
        content: "" !important; // stylelint-disable-line
        position: absolute;
        display: block;
        top: 50%;
        left: 30px;
        width: 0;
        height: 0;
        margin-top: -1px;
        color: inherit;
        border: none;
        border-top: 2px solid transparent;
        border-right: 2px solid;
        border-bottom: 2px solid transparent;
        opacity: .6;
        transition: .3s opacity;
        transform: none;
        will-change: opacity;
    }
    .dropdown > .bropdown-back > a:hover::after,
    .dropdown > .bropdown-back > a.hover::after {
        opacity: 1;
    }

    // social
    .rb-nav-social {
        padding: 40px 74px;

        @media (max-width: 550px) {
            padding-right: 40px;
            padding-left: 40px;
        }

        > ul li {
            display: inline-block;
            > a {
                display: block;
                margin: 3px 6px;
                font-size: 1.13rem;
                color: #d8d8d8;

                &:hover,
                &.hover {
                    color: #fff;
                }
            }
        }
    }
}

// overlay
.rb-navbar-overlay {
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    background-color: rgba(0, 0, 0, .6);
    opacity: 0;
    z-index: 1001;
}
