
/* Style 1 */
.rb-ibox-1 {
    position: relative;
    min-height: 50px;

    .rb-ibox-icon {
        position: absolute;
        width: 50px;
        height: 50px;
        margin-top: 3px;
        font-size: 3.3rem;
        line-height: 50px;
        text-align: center;
    }

    .rb-ibox-cont {
        margin-left: 72px;
    }

    .rb-ibox-title {
        font-weight: 500;
    }
    .rb-ibox-text {
        margin-top: -1px;
        font-size: .97rem;
        font-weight: 500;
        text-transform: uppercase;
    }
}

/* Style 2 */
.rb-ibox-2 {
    position: relative;

    .rb-ibox-cont {
        padding-top: 7px;
    }

    .rb-ibox-title {
        font-size: .96rem;
        font-weight: 500;
        color: $color_titles;
        text-transform: uppercase;
    }
}

/* Style 3 */
.rb-ibox-3 {
    position: relative;
    text-align: center;

    .rb-ibox-icon {
        width: 50px;
        height: 50px;
        margin: 0 auto;
        margin-top: 5px;
        margin-bottom: 17px;
        font-size: 3.3rem;
        line-height: 50px;
        text-align: center;
    }
    .rb-ibox-title {
        font-size: .91rem;
        font-weight: 500;
        text-transform: uppercase;
    }
    .rb-ibox-text {
        margin-top: 8px;
        opacity: .75;
    }
}

/* Titles */
.rb-ibox-title {
    font-size: 1.25rem;

    a {
        color: inherit;
        text-decoration: none;
    }
}
