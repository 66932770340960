// grid system gaps
// vertical gap
.row.vertical-gap > [class*="col-"] {
    padding-top: 30px;
}
.row.vertical-gap {
    margin-top: -30px;
}

// sticky sidebar support
.rb-sidebar-sticky-parent {
    display: flex;
    flex-direction: column;

    > .rb-sidebar {
        flex: 1;
    }
}

// extra-small gap
.row.xs-gap {
    margin-right: -5px;
    margin-left: -5px;
}
.row.xs-gap > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}
.row.xs-gap.vertical-gap > [class*="col-"] {
    padding-top: 10px;
}
.row.xs-gap.vertical-gap {
    margin-top: -10px;
}

// small gap
.row.sm-gap {
    margin-right: -7.5px;
    margin-left: -7.5px;
}
.row.sm-gap > [class*="col-"] {
    padding-right: 7.5px;
    padding-left: 7.5px;
}
.row.sm-gap.vertical-gap > [class*="col-"] {
    padding-top: 15px;
}
.row.sm-gap.vertical-gap {
    margin-top: -15px;
}

// mid gap
.row.md-gap {
    margin-right: -22.5px;
    margin-left: -22.5px;
}
.row.md-gap > [class*="col-"] {
    padding-right: 22.5px;
    padding-left: 22.5px;
}
.row.md-gap.vertical-gap > [class*="col-"] {
    padding-top: 45px;
}
.row.md-gap.vertical-gap {
    margin-top: -45px;
}

// large gap
.row.lg-gap {
    margin-right: -30px;
    margin-left: -30px;
}
.row.lg-gap > [class*="col-"] {
    padding-right: 30px;
    padding-left: 30px;
}
.row.lg-gap.vertical-gap > [class*="col-"] {
    padding-top: 65px;
}
.row.lg-gap.vertical-gap {
    margin-top: -65px;
}

// table
.thead-default th {
    color: $color_dark_4;
    background-color: $color_gray_2;
}
.table thead th,
.table td,
.table th {
    border-color: $color_gray_4;
}


// Modal
.rb-modal {
    .modal-content {
        border: none;
        border-radius: 0;
    }
    .modal-header,
    .modal-footer {
        padding: 25px 30px;
        border-top-color: #f3f3f3;
        border-bottom-color: #f3f3f3;

        .close {
            position: relative;
            margin-top: 0;
            z-index: 2;
        }
    }
    .modal-body {
        padding: 30px;
    }

    &.fade .modal-dialog {
        transition: none;
        transform: translate(0, 0);
    }

    @media #{$media_larger_xs} {
        .modal-dialog {
            margin-top: 60px;
            margin-bottom: 60px;
        }
    }
}
