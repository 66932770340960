.rb-contact-section {
  // transparent
  &.rb-contact-section-transparent {
    background-color: transparent;
  }

  // social
  .rb-contact-section-social {
    padding-bottom: 12px;
    color: $color_titles;

    > ul {
      padding: 0;
      margin: 0;
    }

    > ul li {
      display: block;

      svg {
        margin-right: 10px;
      }
      > a {
        display: block;
        padding: 6px 6px;
        font-size: 1.13rem;
        color: $color_text;
        opacity: 0.4;
        transition: 0.5s opacity;

        &:hover,
        &.hover {
          opacity: 1;
        }

        .social-handle {
          color: #1faeaa;
        }
      }
    }

    &.text-white > ul li > a {
      opacity: 0.9;

      &:hover,
      &.hover {
        opacity: 0.5;
      }
    }
  }
}
