// blockquote
blockquote {
    margin-bottom: 1.5rem;
    font-family: $font_headings;
    font-size: 1.348rem;
    line-height: 1.55;
    color: $color_titles;
}

// style 1
blockquote.rb-blockquote-style-1 {
    display: block;
    max-width: 715px;
    margin: 0 auto;
    font-style: italic;
    text-align: center;

    > p {
        margin-bottom: 1.15rem;
        font-size: 1.685rem;
        line-height: 1.45;

        &::before,
        &::after {
            font-size: 2.2rem;
            line-height: 1;
        }
        &::before {
            content: "“ ";
        }
        &::after {
            content: " ”";
        }
    }

    cite {
        font-family: $font_body;
        font-size: .96rem;
        font-style: normal;
        font-weight: 500;
        text-transform: uppercase;
    }
}
