// main colors
$color_main_1: #1140c9 !default;

$color_text: #5f5f5f !default;
$color_text_blog: #4b4b4b !default;
$color_titles: #171717 !default;
$color_bg: #f6f6f6 !default;
$color_bg_footer: #ebebeb !default;

// dark colors
$color_dark_1: #252525 !default;
$color_dark_2: lighten($color_dark_1, 4%) !default;
$color_dark_3: lighten($color_dark_1, 8%) !default;
$color_dark_4: lighten($color_dark_1, 12%) !default;

// gray colors
$color_gray_1: #fafafa !default;
$color_gray_2: darken($color_gray_1, 1%) !default;
$color_gray_3: darken($color_gray_1, 2%) !default;
$color_gray_4: darken($color_gray_1, 3%) !default;

// main colors list
$colors: (
    "main-1"  : $color_main_1,
    "white"   : #fff,
    "black"   : #000,
    "dark-1"  : $color_dark_1,
    "dark-2"  : $color_dark_2,
    "dark-3"  : $color_dark_3,
    "dark-4"  : $color_dark_4,
    "gray-1"  : $color_gray_1,
    "gray-2"  : $color_gray_2,
    "gray-3"  : $color_gray_3,
    "gray-4"  : $color_gray_4
) !default;

// fonts
$font_headings: "Arvo", serif !default;
$font_body: "Lato", sans-serif !default;
$html_font_size: 15px !default;
$body_line_height: 1.65 !default;
$body_letter_spacing: .004em !default;

// media variables
$media_xxs:           "(max-width: 575px)" !default;
$media_xs:            "(max-width: 767px)" !default;
$media_sm:            "(min-width: 767px) and (max-width: 991px)" !default;
$media_md:            "(min-width: 992px) and (max-width: 1199px)" !default;
$media_lg:            "(min-width: 1200)" !default;

// additional variables
$media_smaller_xs:    "(max-width: 767px)" !default;
$media_smaller_sm:    "(max-width: 991px)" !default;
$media_smaller_md:    "(max-width: 1199px)" !default;
$media_larger_xs:     "(min-width: 576px)" !default;
$media_larger_sm:     "(min-width: 768px)" !default;
$media_larger_md:     "(min-width: 992px)" !default;
$media_larger_lg:     "(min-width: 1200px)" !default;
