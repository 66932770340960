@import url('https://fonts.googleapis.com/css2?family=Arvo&family=Lato:ital,wght@0,400;0,700;1,400&display=swap');

html {
    font-size: $html_font_size;
}

body {
    font-family: $font_body;
    line-height: $body_line_height;
    letter-spacing: $body_letter_spacing;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin-bottom: 1.6rem;
}

b,
strong {
    font-weight: 600;
}

div.lead {
    font-family: $font_headings;
    font-size: 1.68rem;
    line-height: 1.45;
    color: $color_titles;
}
div.lead-sm {
    font-size: 1.25rem;
}

/* font like on headings */
.rb-heading-font {
    font-family: $font_headings;
    letter-spacing: .02em;
}

/* headings */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 1rem;
    font-family: $font_headings;
    font-weight: 600;
    color: $color_titles;
}
.display-1,
.display-2,
.display-3,
.display-4 {
    font-family: $font_headings;
    font-weight: 600;
    color: $color_titles;
}
.display-1 {
    font-size: 4.1rem;
}
.display-2 {
    font-size: 3.6rem;
}
.display-3 {
    font-size: 3.4rem;
}
.display-4 {
    font-size: 2.6rem;
}
.h2,
h2 {
    font-size: 2.1rem;
}
.h3,
h3 {
    font-size: 1.69rem;
}
.h4,
h4 {
    font-size: 1.4rem;
}

/* Sub Title */
.rb-subtitle {
    margin-bottom: 1.1rem;
    font-family: inherit;
    font-size: .93rem;
    text-transform: uppercase;
    letter-spacing: .2em;
}

// style 2
.rb-subtitle-2 {
    font-family: inherit;
    font-size: .96rem;
    font-weight: 500;
    text-transform: uppercase;
}
