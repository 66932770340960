.rb-widget {
    overflow: hidden;

    ~ .rb-widget {
        margin-top: 65px;
    }

    .rb-widget-title {
        margin-bottom: 2.1rem;
        font-size: 1.4rem;
    }
}

// Twitter
.rb-twitter {
    position: relative;
    padding-left: 39px;

    + .rb-twitter {
        margin-top: 20px;
    }

    .rb-twitter-date {
        margin-bottom: 10px;
        font-size: .8rem;
        line-height: 1.4;
        opacity: .6;
    }

    .rb-twitter-icon {
        position: absolute;
        top: 2px;
        left: 0;
        font-size: 1.3rem;
    }
}
