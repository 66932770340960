.rb-progress {
    display: block;
    width: 100%;
    margin-bottom: 13px;

    .rb-progress-line {
        width: 100%;
        height: 5px;
        background-color: #e6e6e6;

        > div {
            height: 100%;
            text-align: right;
            background-color: $color_dark_1;
        }
    }

    .rb-progress-title {
        margin-bottom: 13px;
        font-size: .97rem;
        font-weight: 500;
        color: $color_titles;
        text-transform: uppercase;

        .rb-progress-percent {
            margin-left: 14px;
        }
    }

    // sizes
    &.rb-progress-xs {
        .rb-progress-line {
            height: 1px;
        }
    }
    &.rb-progress-sm {
        .rb-progress-line {
            height: 3px;
        }
    }
    &.rb-progress-md {
        .rb-progress-line {
            height: 8px;
        }
    }
    &.rb-progress-lg {
        .rb-progress-line {
            height: 12px;
        }
    }
    &.rb-progress-xlg {
        .rb-progress-line {
            height: 16px;
        }
    }
}
